export const japanCities = {
  "tokyo-narita": "Tokyo (Narita)",
  "tokyo-haneda": "Tokyo (Haneda)",
  "tokyo-ibaraki": "Tokyo (Ibaraki)",
  "osaka-kensai": "Osaka (Kensai)",
  "nagoya-chubu": "Nagoya (Chubu)",
  "osaka-itami": "Osaka (Itami)",
  fukuoka: "Fukuoka",
  sapporo: "Sapporo",
  naha: "Naha",
  kagoshima: "Kagoshima",
  kumamoto: "Kumamoto",
  sendai: "Sendai",
  nagasaki: "Nagasaki",
  miyazaki: "Miyazaki",
  matsuyama: "Matsuyama",
  hiroshima: "Hiroshima",
  kobe: "Kobe",
  ishigaki: "Ishigaki",
  oita: "Ōita",
  komatsu: "Komatsu",
  takamatsu: "Takamatsu",
  hakodate: "Hakodate",
  okayama: "Okayama",
  kochi: "Kōchi",
  miyako: "Miyako Island",
  kitakyushu: "Kitakyūshū",
  akita: "Akita",
  asahikawa: "Asahikawa",
  tokushima: "Tokushima",
  aomori: "Aomori",
  niigata: "Niigata",
  ube: "Ube",
  izumo: "Izumo",
  ozora: "Ōzora",
  toyama: "Toyama",
  "nagoya-komaki": "Nagoya (Komaki)",
  shizuoka: "Shizuoka",
  kushiro: "Kushiro",
  amami: "Amami",
  yonago: "Yonago",
  saga: "Saga",
  obihiro: "Obihiro",
  hanamaki: "Hanamaki",
  iwakuni: "Iwakuni",
  tottori: "Tottori",
  shonai: "Shōnai",
  misawa: "Misawa",
  fukushima: "Fukushima",
  tsushima: "Tsushima",
  kume: "Kume Island",
}
export type JapanCity = keyof typeof japanCities
